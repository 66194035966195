import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Main } from "./components/main";
import { Features } from "./components/features";
import { KillerFeatures } from "./components/killer-features";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Main mdxType="Main" />
    <Features mdxType="Features" />
    <KillerFeatures mdxType="KillerFeatures" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      